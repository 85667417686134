<template>
  <v-row>
     <v-col cols="12" sm="12" md="12" class="text-left mt-4">
     <v-toolbar-title class="pl-5">{{titleInfo}}</v-toolbar-title>
    <v-spacer></v-spacer>  
    </v-col>  
    <v-col cols="12" sm="12" md="4" class="text-right pl-5">
        <v-select
          v-model="module_id"
          :items="listModule"
          :label="$t('input.select_module')"
          item-text="name"
          item-value="_id"
          @input="selectModule(module_id)"
        ></v-select>
    </v-col>       
    <v-col cols="6" sm="6" md="2" class="text-right pl-5">
      <v-menu
        v-model="v_startDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="pl-5"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="ចាប់ផ្តើម"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on">
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          @input="v_startDate = false"
        >
      </v-date-picker>
    </v-menu>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="6"
      sm="6"
      md="2"
      class="text-right pl-5 "
    >
      <v-menu
        v-model="v_endDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="endDate"
        label="បញ្ចប់"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on">
      </v-text-field>
    </template>
      <v-date-picker
        v-model="endDate"
        @input="v_endDate = false"
      ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="8" sm="8" md="2" class="text-right pl-5">
      <v-text-field
        v-model="v_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
         class="pl-5"
      />
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="4" sm="4" md="2" class="text-right pr-5 mb-5">
      <v-btn 
        class="white--text btn-custom mr-5"
        color="green darken-1"
        depressed
        @click="getData()"
      >
        SEARCH
      </v-btn>
    </v-col>
  </v-row>
  
</template>

<script>
import {mapActions} from "vuex";
import moment from "moment";

export default {
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission',"user", "time","listModule"],
  data() {
    return {
      data: {
         descendingFormat: [true],
          sortByFormat: ['_id._id'],
          descending: true,
          sortBy: null,
          page: 1,
          rowsPerPage: 10,
          fields: [
            "_id.com_id", "_id.com", "_id.con_balance", "_id.admin_id", "_id.admin", "_id.admin_balance", "_id.master_id", "_id.master", "_id.master_balance", "_id.agent_id", "_id.agent", "_id.agent_balance"
            ],
            search: '',
            startDate:  moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            module_id: null
          },
      startDate:  moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
      v_startDate: null,
      v_endDate: null,
      v_search:"",
      module_id: process.env.VUE_APP_SN
    };
  },
 watch: {
    time: function(newVal) {
      // Emit this information to the parents component
    this.startDate = newVal.startDate;
    this.endDate = newVal.endDate;
    } 
  },

 computed: {
   titleInfo: function(){
      if( this.user){
          if(  this.user.role_name == "DEVELOPER" || this.user.role_name == 'MANAGER' || this.user.role_name == "SPECIAL-MANAGER") return "List Agent Statement"
          else if( this.user.role_name == "COMPANY" || this.user.role_name == 'SUB-COMPANY') return "List Agent Statement"
          else if( this.user.role_name == "SENIOR-R" || this.user.role_name == 'SUB-SENIOR-R') return "List Agent Statement"
          else if( this.user.role_name == "MASTER-R" || this.user.role_name == 'SUB-MANAGER-R') return "List Agent Statement"
          else if( this.user.role_name == "AGENT-R" || this.user.role_name == 'SUB-AGENT-R') return "List Agent Statement"
          else return "Loading..."
      }
      else return "Loading..."
    },
    reportUrl: function(){

     if(this.user){
        if(this.user.role_name == "DEVELOPER" || this.user.role_name == 'MANAGER' || this.user.role_name == "SPECIAL-MANAGER") return "getViewAgentSummaryKH"
        else if(this.user.role_name == "COMPANY" || this.user.role_name == 'SUB-COMPANY') return "getViewAgentSummaryKH"
        else if(this.user.role_name == "SENIOR-R" || this.user.role_name == 'SUB-SENIOR-R') return "getViewAgentSummaryKH"
        else if(this.user.role_name == "MASTER-R" || this.user.role_name == 'SUB-MASTER-R') return "getViewAgentSummaryKH"
        else if(this.user.role_name == "AGENT-R" || this.user.role_name == 'SUB-AGENT-R') return "getViewAgentSummaryKH"
        else return null
     }
     else return null
    },
 },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },
    async getData() {
      this.data.startDate = this.startDate;
      this.data.endDate = this.endDate;
      this.data.search = this.v_search;
      this.data.module_id = this.module_id;
      let preData = {
        data : this.data,
        // user_id : this.user._id,
        reportUrl : this.reportUrl
      }
      this.fetchListStatement(preData).then(() => {
            let date = {
              startDate : this.startDate,
              endDate : this.endDate
            }
           this.$emit("child-checkbox", date);
          })
    },
    selectModule(module_id){
      this.data.startDate = this.startDate;
      this.data.endDate = this.endDate;
      this.data.search = this.v_search;
      this.data.module_id = module_id;
      let objModule = this.listModule.filter(e => e._id == module_id);
      let preData = {
        data : this.data,
        reportUrl : this.reportUrl
      }
      this.fetchListStatement(preData).then(() => {
            let date = {
              startDate : this.startDate,
              endDate : this.endDate,
              module_id: this.module_id,
              module_name: objModule ? objModule[0].name: ""
            }
           this.$emit("child-checkbox", date);
          })
      // this.$router.go();
    },
    ...mapActions("$_snkAgentKh", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.btn-custom{
        position: relative;
        // right: 250px;
        top: 10px;        
    }
    .tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
</style>
